import { Typography, ArrowRightTopIcon, UserIcon, Grid, Hidden, SxProps, Lazy } from '@hermes/web-components'
import { useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import NextImage from 'next/image'
import Avatar from '../../../Avatar'
import { SharedLinkData, SocialLinksDataTypes } from '../../../../types'
import { ProfileSocialsWrapper, SocialComponentWrapper, SocialsTitle, SocialsWrapper, WebsiteLink } from './styles'
import { MediaContentWrapper } from '../Header/styles'
import { PostsTitle } from '../Posts/styles'
import { getInitialsFromFullName } from '../../Intro'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { sendMediaStats } from '../../../../api/profile'
import { ProfileContext } from '../../../../providers/AppData/Profile'
import { NOFOLLOW_EXCEPTION } from '../Posts/Card'

interface ProfileSocialsProps {
  socials: SharedLinkData[]
  websiteLink: SharedLinkData
  isBasic: boolean
  isUnclaimed: boolean
  profileImage: string
  profileName: string
  triggerWebAnalytics: () => void
  triggerSocialsAnalytics: (type: string) => void
  articlesAvailable: boolean
}

type SocialComponentProps = {
  isBasic: boolean
  isUnclaimed: boolean
  profileName: string
  profileImage: string
  onClick?: () => void
} & SharedLinkData

const validateLinkProtocol = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url
}

const SocialComponent = ({
  type,
  profileName = '',
  isUnclaimed,
  profileImage,
  link,
  onClick
}: SocialComponentProps) => {
  const initials = getInitialsFromFullName(profileName)
  const [isHover, setIsHover] = useState(false)

  const socialLinksData = useMemo(
    () => ({
      tiktok: {
        // todo: fix lint
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        socialLink: `https://www.tiktok.com/${!link.includes('@') ? `@${link}` : link}`,
        isLightVariant: false,
        imageWidth: '121px',
        imageHeight: '121px',
        iconPosition: { top: '12.5px', right: '-14px' }
      },
      instagram: {
        socialLink: `https://www.instagram.com/${link.replace('@', '')}`,
        isLightVariant: false,
        imageWidth: '111px',
        imageHeight: '111px',
        iconPosition: { top: '17px', right: '-13px' }
      },
      twitter: {
        socialLink: `https://www.twitter.com/${link.replace('@', '')}`,
        isLightVariant: true,
        imageWidth: '147px',
        imageHeight: '126px',
        iconPosition: { top: '9px', left: '27%' }
      },
      facebook: {
        socialLink: `https://www.facebook.com/${link.replace('@', '')}`,
        isLightVariant: true,
        imageWidth: '127px',
        imageHeight: '128px',
        iconPosition: { top: '-8px', right: '5px' }
      }
    }),
    [link]
  )

  const { socialLink, isLightVariant, imageWidth, imageHeight, iconPosition } =
    socialLinksData[type as SocialLinksDataTypes]

  const handleMouseEnter = () => setIsHover(true)
  const handleMouseLeave = () => setIsHover(false)

  return (
    <a href={socialLink} rel="noopener nofollow" onClick={onClick} target="_blank" style={{ textDecoration: 'none' }}>
      <SocialComponentWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isLightVariant={isLightVariant}
      >
        <Grid container height="100%" p="0 11px" gap="16px" alignItems="center" wrap="nowrap">
          <Avatar
            width={86}
            height={86}
            src={isUnclaimed ? undefined : profileImage}
            alt={profileName}
            sx={{ backgroundColor: 'blue.200', zIndex: 1 }}
          >
            <Typography variant="h2">{initials}</Typography>
          </Avatar>
          <Typography
            sx={{
              maxWidth: '60%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              zIndex: 1
            }}
            fontWeight="600"
            fontSize="16px"
            lineHeight="22.4px"
            color="common.white"
          >
            {`@${link.replace('@', '')}`}
          </Typography>
        </Grid>
        <Lazy>
          <div
            style={{
              position: 'absolute',
              zIndex: 0,
              height: imageHeight,
              width: imageWidth,
              ...iconPosition
            }}
          >
            <NextImage
              src={`/hermes-static/images/socials/${type}${isHover ? '-hover' : ''}.svg`}
              alt=""
              width={imageWidth}
              height={imageHeight}
              style={{ margin: 'auto' }}
            />
          </div>
        </Lazy>
      </SocialComponentWrapper>
    </a>
  )
}

const WebsiteLinkComponent = ({
  triggerWebAnalytics,
  sendSocialsAnalytics,
  website,
  sx
}: {
  triggerWebAnalytics: () => void
  sendSocialsAnalytics: (mediaId: number) => void
  website: {
    link: string
    id: number
  }
  sx?: SxProps
}) => {
  const translate = useTranslateMessage()

  const additionalRel = useMemo(
    () => (NOFOLLOW_EXCEPTION.some((exceptionPart) => website.link.includes(exceptionPart)) ? '' : ' nofollow'),
    [website.link]
  )

  return (
    <WebsiteLink
      sx={sx}
      href={validateLinkProtocol(website.link)}
      rel={`noopener${additionalRel}`}
      target="_blank"
      onClick={() => {
        triggerWebAnalytics()
        sendSocialsAnalytics(website.id)
      }}
    >
      <UserIcon sx={{ marginRight: '10px', width: '21px', height: '21px' }} />
      <Typography sx={{ textDecoration: 'underline' }}>
        {translate('media_center.socials.visit_website')}
        <ArrowRightTopIcon
          style={{
            height: '20px',
            marginLeft: '10px',
            marginBottom: '-5px'
          }}
        />
      </Typography>
    </WebsiteLink>
  )
}

const ProfileSocials = ({
  socials,
  isUnclaimed,
  isBasic,
  profileImage,
  profileName,
  triggerWebAnalytics,
  triggerSocialsAnalytics,
  articlesAvailable,
  websiteLink
}: ProfileSocialsProps) => {
  const socialsWithoutWebsite = socials.filter((social) => social.type !== 'website')

  const website = websiteLink
  const translate = useTranslateMessage()
  const profile = useContext(ProfileContext)
  const practiceId = profile?.practice?.id
  const specialistId = profile?.specialist?.id
  const router = useRouter()
  const locale = router.locale || router.defaultLocale!

  const sendSocialsAnalytics = (mediaId: number) => {
    sendMediaStats({
      mediaId,
      practiceId,
      specialistId,
      locale
    })
  }

  return (
    <section id="socials">
      <ProfileSocialsWrapper articlesAvailable={articlesAvailable}>
        <MediaContentWrapper>
          <SocialsTitle container>
            {!!socialsWithoutWebsite.length && (
              <PostsTitle component="h3" color="text.darkNavy">
                {translate('media_center.socials')}
              </PostsTitle>
            )}
            <Hidden smDown>
              {website?.enabled && (
                <WebsiteLinkComponent
                  website={website}
                  sendSocialsAnalytics={sendSocialsAnalytics}
                  triggerWebAnalytics={triggerWebAnalytics}
                />
              )}
            </Hidden>
          </SocialsTitle>
          <SocialsWrapper>
            {socialsWithoutWebsite.map(
              (social) =>
                social.enabled && (
                  <SocialComponent
                    onClick={() => {
                      triggerSocialsAnalytics(social.type)
                      sendSocialsAnalytics(social.id)
                    }}
                    key={`social-link-${social.id}`}
                    profileName={profileName}
                    isBasic={isBasic}
                    isUnclaimed={isUnclaimed}
                    profileImage={profileImage}
                    {...social}
                  />
                )
            )}
            <Hidden smUp>
              {website?.enabled && (
                <WebsiteLinkComponent
                  website={website}
                  sx={{
                    marginTop: socialsWithoutWebsite.length ? '20px' : 0
                  }}
                  sendSocialsAnalytics={sendSocialsAnalytics}
                  triggerWebAnalytics={triggerWebAnalytics}
                />
              )}
            </Hidden>
          </SocialsWrapper>
        </MediaContentWrapper>
      </ProfileSocialsWrapper>
    </section>
  )
}

export default ProfileSocials
