import { styledComponent, Box, Grid, Link } from '@hermes/web-components'

export const PostCardWrapper = styledComponent(Link)(({ theme }) => ({
  borderRadius: '8px',
  textAlign: 'left',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  padding: '16px',
  width: '100%',
  height: '100%',
  textDecoration: 'none',
  border: '1.5px solid transparent',
  contentVisibility: 'auto',
  containIntrinsicHeight: '600px',
  '&:hover': {
    backgroundColor: theme.palette.system.white400,
    boxShadow:
      '0px 54px 82px rgba(2, 3, 3, 0.03), 0px 22px 98px rgba(2, 3, 3, 0.02), 0px 23px 28px rgba(2, 3, 3, 0.04)',
    border: '1.5px solid #EAEEFC'
  }
}))

export const ContentWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'horizontal'
})<{ horizontal: boolean }>(({ theme, horizontal }) => ({
  display: 'grid',
  height: '100%',
  gap: '15px',
  ...(horizontal
    ? {
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: '1fr 1fr',
          gap: '24px'
        }
      }
    : {})
}))

export const PostCardContent = styledComponent(Grid, {
  shouldForwardProp: (prop: string) => prop !== 'horizontal'
})<{ horizontal: boolean }>(({ theme, horizontal }) => ({
  h4: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22.4px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical'
  },
  p: {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical'
  },
  ...(horizontal
    ? {
        [theme.breakpoints.up('sm')]: {
          h4: {
            ...theme.typography.h3,
            fontWeight: 700,
            lineHeight: '32.04px',
            WebkitLineClamp: '3'
          },
          p: {
            fontSize: '16px',
            lineHeight: '22.4px',
            WebkitLineClamp: '6'
          }
        }
      }
    : {})
}))

export const ImageWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'horizontal'
})<{ horizontal: boolean }>(({ theme, horizontal }) => ({
  height: '170px',
  position: 'relative',
  img: {
    borderRadius: '4px',
    height: '170px',
    width: '100%',
    objectFit: 'cover'
  },
  ...(horizontal
    ? {
        [theme.breakpoints.up('sm')]: {
          height: '380px',
          img: {
            borderRadius: 0,
            height: '380px'
          }
        }
      }
    : {})
}))
