const replaceWithDashRegexp = /[\s_]/g

const isValidSlug = (slug: string) => !/_/.test(slug)

const checkProfileSlugRedirect = (slug: string): string | null => {
  if (isValidSlug(slug)) {
    return null
  }

  return slug.replace(replaceWithDashRegexp, '-')
}

const fixSlug = (slug: string): string => {
  const fixedSlug = checkProfileSlugRedirect(slug)
  return !fixedSlug ? slug : fixedSlug
}

export { checkProfileSlugRedirect, fixSlug }
