import { useState, useMemo, useContext, useRef, useEffect, RefObject, ReactNode } from 'react'
import {
  Paper,
  Typography,
  Grid,
  ExpandMoreIcon,
  Chip,
  styledComponent,
  Hidden,
  useTheme,
  Box,
  NoReviewIcon,
  NoEndorsementsIcon,
  Stack,
  NoReviewsImageIcon,
  useMediaQuery,
  CheckboxCircle,
  LocalPhoneIcon,
  QuestionAnswerIcon,
  EventAvailableIcon,
  Link,
  Rating,
  DonutChart
} from '@hermes/web-components'
import { useDateTimeCorrector } from '../../hooks/useDatetimeCorrector'
import ReviewStatisticRow from '../profiles/Reviews/ReviewStatisticRow'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import { isOlderThanNMonth } from '../../utils/date'
import FullWidthContainer from '../FullWidthContainer'
import { AppDataContext } from '../../providers/AppData'
import { SearchReviewsContext } from '../../providers/SearchReviews'
import { fontFamilyESKlarheitKurrent } from '../customTheme/fonts'
import { ProfilePeerRecommendation, Statistic, ProfileReview, ProfileReviewStatistic } from '../../types'
import { truncateString } from '../../utils/strings'
import { ContactDetailsProfile, Event } from '../profiles/ProfileModal/types'
import { ContactDescription } from '../profiles/ProfileModal/SpecialistPracticeBookingCard'
import { DetailsLink } from '../RevealLink'
import { RequestTypes } from '../../constants/profile'
import { StyledRevealsContainer } from './styles'
import getImageWithWWW from '../../utils/getImageWithWWW'
import Avatar from '../Avatar'
import useTrackWindowHeight from '../../hooks/useTrackWindowHeight'

type RatingBarProps = {
  profileName: string
  isBasic?: boolean
  externalIdProfile: string
  averageRating: number
  peerRecommendationsCount?: number
  reviewsTotal: number
  peerRecommendation?: ProfilePeerRecommendation
  latestReview?: ProfileReview
  type?: 'clinic' | 'specialist'
  onGetInTouch?: () => void
  onBookAppointment?: (button?: string) => void
  hideBookAppointment?: boolean
  statistic?: Statistic[]
  review: ProfileReviewStatistic | null
  isUnclaimed?: boolean
  reviewStatistic?: Statistic[]
  contacts: ContactDetailsProfile[]
  slug: string
  endAnchorRef?: RefObject<HTMLDivElement>
  bookingCalendarComponent?: ReactNode | null
}

type TopKeywordType = {
  name: string
  variant: string
  value: number
  color: string
  hover: string
}

const SectionWrapper = styledComponent(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.secondary.light}`,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  [theme.breakpoints.down('xs')]: {
    px: 0
  }
}))

const revealBookKey = 'bookAppointment.reveal.book'

const RootWrapper = styledComponent(Paper, { shouldForwardProp: (propName: string) => propName !== 'elementOffset' })<{
  elementOffset: number
}>(({ theme, elementOffset }) => ({
  boxShadow: '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)',
  maxWidth: 415,
  marginLeft: 'auto',
  width: '100%',
  color: theme.palette.text.darkNavy,
  border: `1px solid ${theme.palette.secondary.light}`,
  [theme.breakpoints.down('lg')]: {
    backgroundColor: 'inherit',
    border: 'none',
    maxWidth: 'none',
    boxShadow: 'none'
  },
  [theme.breakpoints.up('lg')]: {
    position: 'sticky',
    top: elementOffset,
    zIndex: 1051
  }
}))

const StyledChip = styledComponent(Chip)(() => ({
  border: 1,
  color: 'inherit',
  maxWidth: '100%'
}))

const RootContainer = styledComponent(Box, {
  shouldForwardProp: (propName: string) => propName !== 'height'
})<{
  height: string
}>(({ theme, height }) => ({
  width: 'calc(100% - 32px)',
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: 0,
    right: 0,
    height,
    maxWidth: 415
  }
}))

const RecommenderComponent = ({
  recommender,
  language
}: {
  recommender: ProfilePeerRecommendation['recommender']
  language: string
}) => {
  const { title, firstName, lastName } = recommender

  const userTitle = `${title[language] || title.en || ''} ${firstName[language]} ${lastName[language]}`

  return (
    <Grid mt={1} container alignContent="center" alignItems="center">
      <Avatar alt={userTitle} width={24} height={24} src={getImageWithWWW(recommender.images?.logo)} />
      <Typography ml={1} variant="body2" fontWeight="500" color="text.primary">
        {userTitle}
      </Typography>
    </Grid>
  )
}

type SectionWrapperComponentProps = {
  title: string
  latestReview?: ProfileReview | ProfilePeerRecommendation
  recommender?: ProfilePeerRecommendation['recommender']
  language: string
  noReviewIcon: JSX.Element
  noReviewTitle: string
  outDatedTitle?: string
  href: string
}

const SectionWrapperComponent = ({
  title,
  latestReview,
  recommender,
  language,
  noReviewIcon,
  noReviewTitle,
  outDatedTitle,
  href
}: SectionWrapperComponentProps) => {
  const textLatestReview = latestReview && 'text' in latestReview ? latestReview.text : latestReview?.recommendation
  const cuttedLatestReview = !!textLatestReview && truncateString(textLatestReview)
  const isOlderThanSixMonth = isOlderThanNMonth(6, latestReview?.createdAt)
  const dateTime = useDateTimeCorrector(latestReview?.createdAt)

  return (
    <SectionWrapper container direction="column">
      <Grid container justifyContent="space-between" mb={1}>
        <Typography textTransform="uppercase" variant="body2" component="h3">
          {title}
        </Typography>
        {!!latestReview && !(isOlderThanSixMonth && outDatedTitle) && (
          <Typography variant="subtitle2" component="p" fontWeight={400} color="text.secondary">
            {dateTime}
          </Typography>
        )}
      </Grid>
      {!!latestReview && !(isOlderThanSixMonth && outDatedTitle) ? (
        <>
          <Typography
            component={Link}
            href={href}
            variant="body1"
            sx={{ maxHeight: 45, maxWidth: '100%', overflow: 'hidden', color: 'inherit', textDecoration: 'inherit' }}
            color="text.black900"
          >
            {cuttedLatestReview}
          </Typography>
          {!!recommender && <RecommenderComponent recommender={recommender} language={language} />}
        </>
      ) : (
        <Box display="flex">
          {noReviewIcon}
          <Typography>{isOlderThanSixMonth && outDatedTitle ? outDatedTitle : noReviewTitle}</Typography>
        </Box>
      )}
    </SectionWrapper>
  )
}

// todo: fix complexity
// eslint-disable-next-line complexity
function RatingBar({
  profileName,
  isBasic = false,
  averageRating,
  peerRecommendationsCount = 0,
  reviewsTotal,
  externalIdProfile,
  peerRecommendation,
  type,
  onBookAppointment,
  statistic = [],
  latestReview,
  review,
  hideBookAppointment = false,
  isUnclaimed = false,
  endAnchorRef,
  contacts = [],
  slug,
  bookingCalendarComponent = null
}: RatingBarProps) {
  const { language } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const theme = useTheme()
  const isClinic = type === 'clinic'
  const isBookingAvailable = !!bookingCalendarComponent

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))
  const windowHeight = useTrackWindowHeight()
  const variants = ['aqua', 'coral', 'lightBlue', 'blue']
  const statColors = [
    theme.palette.system.aqua600,
    theme.palette.system.coral200,
    theme.palette.system.lightBlue400,
    theme.palette.secondary.light
  ] as string[]

  const statHover = [
    theme.palette.system.aqua,
    theme.palette.system.coral400,
    theme.palette.system.lightBlue600,
    theme.palette.blue[200]
  ] as string[]

  const [expanded, setExpanded] = useState(true)
  const [activeKeyword, setActiveKeyword] = useState<TopKeywordType | null>(null)
  const toggleExpandedContent = () => setExpanded((prev) => !prev)
  const otherValue = translate('other')
  const { topStats, totalStatCount } = useMemo(() => {
    const top3Stat = (statistic.slice(0, 3) || []).map((stat) => ({
      value: stat.tagCount,
      name: stat.tagName[language]
    }))

    const count = statistic.length
    const topTotal = top3Stat.reduce((prev: number, curr) => prev + curr.value, 0)
    const total = statistic.reduce((prev: number, curr) => prev + curr.tagCount, 0)

    const topStatsList: TopKeywordType[] = (
      count && count > 3 ? [...top3Stat, { name: otherValue, value: total - topTotal }] : top3Stat
    ).map((stat, idx) => ({
      ...stat,
      color: statColors[idx],
      hover: statHover[idx],
      variant: variants[idx]
    }))

    return {
      topStats: topStatsList,
      totalStatCount: total,
      topStatCount: topTotal
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  const el = useRef<HTMLDivElement>(null)
  const [elementOffset, setElementOffset] = useState(70)

  const ratingBarWrapperHeight = useMemo(() => {
    if (endAnchorRef?.current) {
      return `${endAnchorRef?.current.getBoundingClientRect().top + window.pageYOffset - 200}px`
    }
    return windowHeight
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endAnchorRef?.current, windowHeight])

  useEffect(() => {
    const calculateElementPosition = () => {
      const elementHeight = el.current?.offsetHeight || 0
      setElementOffset(Math.min(window.innerHeight - elementHeight, 70))
    }

    window.addEventListener('resize', calculateElementPosition)
    window.addEventListener('load', calculateElementPosition)
    return () => {
      window.removeEventListener('resize', calculateElementPosition)
      window.removeEventListener('load', calculateElementPosition)
    }
  }, [])

  const {
    state: { filters, keywordNames },
    changeKeyword
  } = useContext(SearchReviewsContext)

  const onSelect = (value: string) => changeKeyword(value === otherValue || value === filters.keyword ? '' : value)

  useEffect(() => {
    const activeKeywordFromStorage = localStorage.getItem(`activeKeyword-${slug}`)

    if (statistic?.length && activeKeywordFromStorage && activeKeywordFromStorage?.length) {
      const activeKeywordFromStatistic = statistic.find((x) => x.tagName[language] === activeKeywordFromStorage)
      const activeKeywordFromKeywordNames = keywordNames?.some((keyword) => keyword.name === activeKeywordFromStorage)

      if (activeKeywordFromStatistic && activeKeywordFromKeywordNames) {
        setActiveKeyword({
          name: activeKeywordFromStatistic.tagName[language],
          variant: 'aqua',
          value: activeKeywordFromStatistic.tagCount,
          color: 'rgba(0, 229, 208, 0.6)',
          hover: '#00E5D0'
        })
      }
    }
    localStorage.removeItem(`activeKeyword-${slug}`)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic])

  useEffect(() => {
    if (activeKeyword) {
      onSelect(activeKeyword.name)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeyword])

  const isLessThenLg = useMediaQuery(theme.breakpoints.down('lg'))

  const onClickBookAppointment = (button: string, label: string) => {
    if (onBookAppointment) {
      onBookAppointment(button)
    }
    window.dataLayer.push({
      event: 'prf_pg_cont_button',
      cont_prf_name: profileName,
      cont_prf_type: type === 'clinic' ? 'Practice' : 'Specialist',
      cont_interaction_label: label,
      cont_prf_uuid: externalIdProfile
    })
  }

  const onClickReveal = (event: Event, label: string, targetName: string, targetUuid: string) => {
    window.dataLayer.push({
      event,
      cont_prf_name: profileName,
      cont_prf_type: type === 'clinic' ? 'Practice' : 'Specialist',
      cont_enquired_at_name: targetName,
      cont_enquired_at_uuid: targetUuid,
      cont_interaction_label: label,
      cont_prf_uuid: externalIdProfile
      // todo: fix typing
    } as any)
  }

  const topStatsKeywordWithActive = [
    activeKeyword,
    ...topStats.slice(activeKeyword ? 1 : 0, 4).filter((k) => k.name !== activeKeyword?.name)
  ]

  const shouldShowNewBadge = useMemo(
    () => isUnclaimed || (!reviewsTotal && !peerRecommendationsCount),
    [isUnclaimed, reviewsTotal, peerRecommendationsCount]
  )

  const emailHref = isMobile ? `mailto:${contacts?.[0]?.email}?subject=${translate('email.subject')}` : undefined

  return (
    <FullWidthContainer mobileOnly sx={{ backgroundColor: { xs: 'background.default' } }}>
      <RootContainer height={ratingBarWrapperHeight}>
        <RootWrapper ref={el} elementOffset={elementOffset}>
          <Grid sx={{ py: 2, px: { xs: 0, lg: 2 }, pb: 0 }} container justifyContent="space-between">
            <Typography variant="body2" component="h3" textTransform="uppercase">
              {translate('reviews.trust_rating')}
            </Typography>
            {shouldShowNewBadge && (
              <Stack direction="row" sx={{ backgroundColor: '#FFEFEF', p: 0.5, px: 1, borderRadius: 16 }}>
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {translate('new')}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid
            container
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            sx={{ py: 2, px: { xs: 0, lg: 2 }, pb: 0, pt: 1 }}
            wrap="nowrap"
          >
            <Typography variant="h1" component="p" sx={{ lineHeight: 1 }}>
              {!isBasic && !isUnclaimed && averageRating ? averageRating.toFixed(2) : '--'}
            </Typography>
            <Grid width="max-content" container flexDirection="column" justifyContent="end">
              {!!reviewsTotal && !isBasic && !isUnclaimed && (
                <Link underline="none" color="#051851" href="#reviews" variant="body2">
                  <span style={{ fontFamily: fontFamilyESKlarheitKurrent, fontSize: '21px', fontWeight: 700 }}>
                    {reviewsTotal}
                  </span>{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    {translate('patient_reviews', { count: reviewsTotal })}
                  </span>
                </Link>
              )}
              {!!peerRecommendationsCount && (
                <Link underline="none" color="#051851" href="#endorsements" variant="body2">
                  <span style={{ fontFamily: fontFamilyESKlarheitKurrent, fontSize: '21px', fontWeight: 700 }}>
                    {peerRecommendationsCount}
                  </span>{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    {translate('peer_endorsements', { count: peerRecommendationsCount })}
                  </span>
                </Link>
              )}
            </Grid>
          </Grid>
          {!(isBasic || isUnclaimed || !averageRating) && (
            <Box pb={2}>
              <Box display="flex" sx={{ px: { lg: 2, xs: 0 }, mt: 1, mb: expanded ? 0 : 1 }} alignItems="center">
                <Rating value={averageRating} sx={{ fontSize: 24 }} readOnly />
                <ExpandMoreIcon
                  sx={!expanded ? { transform: 'rotate(180deg)' } : {}}
                  fontSize="inherit"
                  onClick={toggleExpandedContent}
                />
              </Box>
              {!expanded && (
                <Box>
                  <ReviewStatisticRow
                    rating={review?.overallExperience}
                    name={translate('reviews.overallExperience')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.bedsideManner}
                    name={translate('reviews.bedsideManner')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.explanation}
                    name={translate('reviews.explanation')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.cleanliness}
                    name={translate('reviews.cleanliness')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.friendliness}
                    name={translate('reviews.friendliness')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.waitTime}
                    name={translate('reviews.wait_time')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.facilities}
                    name={translate('reviews.facilities')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.careAndSupport}
                    name={translate('reviews.careAndSupport')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.ease}
                    name={translate('reviews.easy')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.quality}
                    name={translate('reviews.quality')}
                    noSpaceBefore={isLessThenLg}
                  />
                  <ReviewStatisticRow
                    rating={review?.impact}
                    name={translate('reviews.impact')}
                    noSpaceBefore={isLessThenLg}
                  />
                </Box>
              )}
            </Box>
          )}
          {!(isBasic || isUnclaimed) &&
            (!isBookingAvailable || isLaptop ? (
              <>
                {!!topStats.length && (
                  <Grid container mb={2} direction="column" sx={{ px: { lg: 2 } }}>
                    <Typography
                      textTransform="uppercase"
                      color="text.dark"
                      variant="body2"
                      component="h3"
                      sx={{ whiteSpace: 'nowrap', pb: { md: 1, xs: 1.5 } }}
                    >
                      {translate('reviews.total_recommendations_count_for', {
                        total: totalStatCount
                      })}
                    </Typography>
                    <Grid container item xs direction="row" wrap="nowrap" alignItems="center">
                      <DonutChart width={100} height={100} data={topStats} thickness={6} />
                      <Grid ml={1} container maxWidth="60%" item alignContent="start" spacing={1}>
                        {topStatsKeywordWithActive.map((keyword) => {
                          if (!keyword) {
                            return null
                          }

                          const { name, value, variant, color } = keyword
                          const isKeywordSelected = filters.keyword === name

                          return (
                            <Grid
                              container
                              item
                              key={`${name}-${value}-${variant}`}
                              xs={12}
                              alignItems="center"
                              spacing={0.5}
                              wrap="nowrap"
                            >
                              <Box
                                sx={{
                                  marginRight: 1,
                                  backgroundColor: color,
                                  width: 8,
                                  height: 8,
                                  flexShrink: 0,
                                  borderRadius: '50%'
                                }}
                              />
                              <StyledChip
                                // @ts-ignore
                                variant={variant}
                                onClick={() => {
                                  onSelect(name)
                                }}
                                clickable
                                sx={{
                                  '.MuiChip-label': {
                                    paddingLeft: isKeywordSelected ? 0 : '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '3px',
                                    ...theme.typography.caption
                                  }
                                }}
                                component="a"
                                href="#reviews"
                                label={
                                  <>
                                    {isKeywordSelected && (
                                      <CheckboxCircle
                                        sx={{
                                          marginRight: isKeywordSelected ? '2px' : 0,
                                          width: '18.33px'
                                        }}
                                      />
                                    )}
                                    <span
                                      style={{
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                      }}
                                    >
                                      {name}
                                    </span>
                                    {!isKeywordSelected && `(${value})`}
                                  </>
                                }
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                container
                py="16px"
                px={{ lg: 2 }}
                direction="column"
                sx={{ borderTop: '1px solid #D5DEF9', borderBottom: '1px solid #D5DEF9' }}
              >
                <Typography
                  textTransform="uppercase"
                  variant="body2"
                  component="h3"
                  fontWeight={600}
                  whiteSpace="nowrap"
                  color="#112971"
                  mb="8px"
                >
                  {totalStatCount} recommendations for:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="8px">
                  {topStatsKeywordWithActive?.filter(Boolean)?.map((keyword) => {
                    if (!keyword) {
                      return null
                    }

                    const { name, value, variant } = keyword

                    const isKeywordSelected = filters.keyword === name

                    return (
                      <Chip
                        component={Link}
                        href="#reviews"
                        key={`${name}-${value}-${variant}`}
                        onClick={() => onSelect(name)}
                        label={
                          <Box display="flex" alignItems="center" gap="8px">
                            {isKeywordSelected && <CheckboxCircle sx={{ width: '22px', height: '22px' }} />}
                            <Typography
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              variant={isKeywordSelected ? 'body2' : 'caption'}
                              color={isKeywordSelected ? '#1A3586' : '#051851'}
                              fontWeight={500}
                            >
                              {keyword.name}
                            </Typography>
                          </Box>
                        }
                        sx={{
                          ':hover': {
                            backgroundColor: isKeywordSelected ? 'rgba(0, 229, 208, 0.3)' : '#CCFAF6'
                          },
                          backgroundColor: isKeywordSelected ? 'rgba(0, 229, 208, 0.2)' : 'rgb(234, 238, 252)',
                          '.MuiChip-label': { paddingLeft: isKeywordSelected ? '2px' : '8px' },
                          cursor: 'pointer'
                        }}
                      />
                    )
                  })}
                </Box>
              </Grid>
            ))}
          {!isBookingAvailable &&
            (!isUnclaimed ? (
              <Hidden lgDown>
                <SectionWrapperComponent
                  title={translate('reviews.recent_patient_review')}
                  latestReview={!isBasic ? latestReview : undefined}
                  language={language}
                  noReviewIcon={<NoReviewIcon sx={{ width: 48, height: 48, mr: 2 }} />}
                  noReviewTitle={translate(
                    isClinic ? 'reviews.no_reviews_practice' : 'reviews.not_collecting_specialist'
                  )}
                  outDatedTitle={translate(isClinic ? 'no_review_over_6_month_clinic' : 'no_review_over_6_month')}
                  href="#reviews"
                />
                {type === 'specialist' && (
                  <SectionWrapperComponent
                    title={translate('peerRecommendations.latest_recommendation')}
                    latestReview={peerRecommendation}
                    recommender={peerRecommendation?.recommender}
                    language={language}
                    noReviewIcon={<NoEndorsementsIcon sx={{ width: 48, height: 48, mr: 2 }} />}
                    noReviewTitle={translate('peerRecommendations.not_collecting_specialist')}
                    href="#endorsements"
                  />
                )}
              </Hidden>
            ) : (
              <SectionWrapper sx={{ pt: 4.5, pb: 3, display: 'flex', justifyContent: 'center' }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item display="flex" justifyContent="center">
                    <NoReviewsImageIcon />
                  </Grid>
                  <Grid item display="flex" justifyContent="center">
                    <Typography color="text.black900">
                      {translate(isClinic ? 'reviews.no_reviews_practice' : 'reviews.no_reviews_specialist')}
                    </Typography>
                  </Grid>
                </Grid>
              </SectionWrapper>
            ))}

          {isBookingAvailable && !isLaptop && bookingCalendarComponent ? bookingCalendarComponent : null}
          {!isBookingAvailable && !hideBookAppointment && contacts?.length >= 1 && (
            <StyledRevealsContainer>
              {isClinic ? (
                <>
                  {!!contacts[0].phone && (
                    <ContactDescription
                      contactValue={contacts[0].phone}
                      description={translate('phone_no')}
                      href={`tel:${contacts[0].phone}`}
                      revealName={isMobile ? translate('call') : contacts[0].phone}
                      slug={contacts[0].slug}
                      active
                      skipReveal={isMobile}
                      icon={<LocalPhoneIcon />}
                      revealTitle={translate('call')}
                      requestType={RequestTypes.PHONE}
                      onClickLinkHandler={() =>
                        onClickReveal(
                          isMobile ? 'prf_pg_cont_popup_reveal_tel' : 'prf_pg_cont_popup_tel_link',
                          'Call',
                          contacts[0].contactName as string,
                          contacts[0].externalId
                        )
                      }
                      onClickRevealHandler={() =>
                        onClickReveal(
                          'prf_pg_cont_popup_reveal_tel',
                          'Call',
                          contacts[0].contactName as string,
                          contacts[0].externalId
                        )
                      }
                    />
                  )}
                  {!!contacts[0].email && (
                    <ContactDescription
                      contactValue={contacts[0].email}
                      description={translate('email')}
                      href={emailHref}
                      revealLink={emailHref}
                      revealName={isMobile ? translate('email') : contacts[0].email}
                      slug={contacts[0].slug}
                      active
                      skipReveal={isMobile}
                      icon={<QuestionAnswerIcon />}
                      revealTitle={translate('bookAppointment.reveal.email')}
                      requestType={RequestTypes.EMAIL}
                      onClickLinkHandler={() => {
                        !isMobile && navigator.clipboard.writeText(contacts[0].email || '')
                        onClickReveal(
                          'prf_pg_cont_popup_reveal_email',
                          'Email',
                          contacts[0].contactName as string,
                          contacts[0].externalId
                        )
                      }}
                      onClickRevealHandler={() =>
                        onClickReveal(
                          'prf_pg_cont_popup_reveal_email',
                          'Email',
                          contacts[0].contactName as string,
                          contacts[0].externalId
                        )
                      }
                    />
                  )}
                  {!!contacts[0].externalBookingLink && (
                    <ContactDescription
                      contactValue={contacts[0].externalBookingLink}
                      description={translate('profile.booking')}
                      revealName={translate(revealBookKey)}
                      href={contacts[0].externalBookingLink}
                      icon={<EventAvailableIcon />}
                      requestType={RequestTypes.EXTERNAL_BOOKING_LINK}
                      revealTitle={translate(revealBookKey)}
                      skipReveal
                      slug={contacts[0].slug}
                      active
                      primaryRevealStyle
                      onClickLinkHandler={() =>
                        onClickReveal(
                          'prf_pg_book_popup_booking_link',
                          'Book',
                          contacts[0].contactName as string,
                          contacts[0].externalId
                        )
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  {contacts.some(({ phone }) => !!phone) && (
                    <DetailsLink type="primary" sx={{ flex: 1 }} onClick={() => onClickBookAppointment('call', 'Call')}>
                      <LocalPhoneIcon />
                      <p>{translate('call')}</p>
                    </DetailsLink>
                  )}
                  {contacts.some(({ email }) => !!email) && (
                    <DetailsLink
                      type="primary"
                      sx={{ flex: 1 }}
                      onClick={() => onClickBookAppointment('email', 'Email')}
                    >
                      <QuestionAnswerIcon />
                      <p>{translate('email')}</p>
                    </DetailsLink>
                  )}
                  {contacts.some(({ externalBookingLink }) => !!externalBookingLink) && (
                    <DetailsLink type="primary" sx={{ flex: 1 }} onClick={() => onClickBookAppointment('book', 'Book')}>
                      <EventAvailableIcon />
                      <p>{translate(revealBookKey)}</p>
                    </DetailsLink>
                  )}
                </>
              )}
            </StyledRevealsContainer>
          )}
        </RootWrapper>
      </RootContainer>
    </FullWidthContainer>
  )
}

export default RatingBar
